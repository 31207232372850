import Vue from "vue";
import Router from "vue-router";
import store from "../store";

Vue.use(Router);
const routes = [
  //一级路由
  {
    path: "/login",
    name: "Login",
    meta: { requireAuth: false },
    component: require("@/views/Login").default,
  },
  {
    path: "/",
    name: "Home",
    meta: { requireAuth: true },
    component: require("@/views/Home").default,
    children: [],
    redirect: "/dashboard",
  },
  // {
  //     path: '*',
  //     redirect: '/'
  // }
];
const router = new Router({
  mode: "history",
  routes,
});
//Error: Loading chunk 24 failed.
//当捕获到Loading chunk {n} failed的错误时我们重新渲染目标页面
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
//定义路由添加方法，避免重复添加路由
router.$addRoutes = (params) => {
  // router.matcher = new Router({mode: 'history'}).matcher;
  router.matcher = new Router().matcher;
  let menus = params.concat(routes);
  menus.forEach(function (route) {
    router.addRoute(route);
  });
  // router.addRoutes(params.concat(routes));
};
router.beforeEach((to, from, next) => {
  if (
    to.matched.some((record) => record.meta.requireAuth) ||
    to.name === null
  ) {
    if (store.state.User.Authorization) {
      if (store.getters.TokenExpire - 60000 * 10 <= new Date().getTime()) {
        // 最后1分钟刷新(刷新得到，获取新的token，获取不到则在响应里面提示跳转登录页面)
        store.dispatch("refreshToken");
      } else {
        // 还没进入最后刷新时间
        if (to.name === null) {
          store.dispatch("initRoutes");
        }
        next();
      }
    } else {
      store.dispatch("Logout");
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else {
    next();
  }
});
export default router;
