let mqtt = require("mqtt");
export default {
  client: null,
  // 建立mqtt连接
  connect() {
    if (this.client == null) {
      this.client = mqtt.connect("wss://ssp.coalsmine.com/ws", {
        clientId: this.getuuid,
        keepalive: 30,
        reconnectInterval: 100,
        reconnectPeriod: 1000, //两次重新连接之间的间隔。通过设置为0禁用自动重新连接。
        connectTimeout: 1000, //接收连接前等待的时间
        protocolId: "MQTT",
        protocolVersion: 4,
        clean: true, //设置为false可在脱机时接收QoS 1和2消息
        username: "root",
        password: "device@coalmine",
      });
      this.client.on("error", (error) => {
        console.log("Mqtt client error：", error);
        this.client.end();
      });
      this.client.on("offline", (error) => {
        console.log("Mqtt client offline", error);
      });
      this.client.on("disconnect", () => {
        console.log("Mqtt client disconnect");
      });
      this.client.on("close", () => {
        console.log("Mqtt client closed");
      });
      this.reconnect();
    }
  },
  reconnect() {
    this.client.on("reconnect", (error) => {
      console.log("Mqtt reconnect:", error);
    });
  },
  disconnect() {
    if (this.client) {
      this.client.end();
    }
  },
  // 连接成功后订阅
  subscribe(topic, callback, error) {
    this.connect();
    this.client.on("connect", () => {
      console.log("MQTT connect success");
      this.client.subscribe(topic, { qos: 2 }, (err, granted) => {
        if (err) {
          error(err, granted);
        } else {
          console.log("订阅成功");
        }
      });
      this.client.on("error", (err) => {
        error("mqtt链接出错：", err);
      });
      this.client.on("message", (topic, message) => {
        callback(topic, message);
      });
    });
  },
  publish(topic, message) {
    this.connect();
    this.client.publish(topic, JSON.stringify(message), { qos: 1 });
  },
  getuuid() {
    var uid = [];
    var hexDigits = "0123456789abcdefghijklmnopqrst";
    for (var i = 0; i < 32; i++) {
      uid[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    uid[6] = "4";
    uid[15] = hexDigits.substr((uid[15] & 0x3) | 0x8, 1);
    var uuid = uid.join("");
    return "client_" + uuid;
  },
};
