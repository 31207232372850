Date.prototype.getWeek = function (number) {
  switch (number) {
    case 1:
      return "一";
    case 2:
      return "二";
    case 3:
      return "三";
    case 4:
      return "四";
    case 5:
      return "五";
    case 6:
      return "六";
    case 0:
      return "日";
  }
};

Date.prototype.Format = function (fmt) {
  var o = {
    "M+": this.getMonth() + 1, //月份
    "d+": this.getDate(), //日
    "h+": this.getHours(), //小时
    "m+": this.getMinutes(), //分
    "s+": this.getSeconds(), //秒
    "q+": Math.floor((this.getMonth() + 3) / 3), //季度
    S: this.getMilliseconds(), //毫秒
  };
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(
      RegExp.$1,
      (this.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  for (var k in o)
    if (new RegExp("(" + k + ")").test(fmt))
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length)
      );
  return fmt;
};

Date.prototype.addDay = function (days, format) {
  if (days > 0) {
    this.setDate(this.getDate() + days); // 获取AddDayCount天后的日期
  } else {
    this.setDate(this.getDate() - Math.abs(days)); // 获取AddDayCount天后的日期
  }
  var y = this.getFullYear();
  var m = this.getMonth() + 1; // 获取当前月份的日期
  var d = this.getDate();
  if (format) {
    return new Date(y + "-" + m + "-" + d).Format(format);
  } else {
    return y + "-" + m + "-" + d;
  }
};
//格式化时间
Date.prototype.formateDate = function (datetime) {
  function addDateZero(num) {
    return num < 10 ? "0" + num : num;
  }
  let d = new Date(datetime);
  let formatdatetime =
    d.getFullYear() +
    "-" +
    addDateZero(d.getMonth() + 1) +
    "-" +
    addDateZero(d.getDate()) +
    " " +
    addDateZero(d.getHours()) +
    ":" +
    addDateZero(d.getMinutes()) +
    ":" +
    addDateZero(d.getSeconds());
  return formatdatetime;
};
//把时间戳转时间
Date.prototype.toDate = function (string) {
  let year = new Date(string).getFullYear();
  let month = new Date(string).getMonth() + 1;
  let date = new Date(string).getDate();
  let hour = new Date(string).getHours();
  let minute = new Date(string).getMinutes();
  let second = new Date(string).getSeconds();
  let time = new Date().formateDate(
    year + "-" + month + "-" + date + " " + hour + ":" + minute + ":" + second
  );
  return time;
};
/**
 * 根据元素值获取索引
 */
Array.prototype.indexOf = function (value) {
  for (var i = 0; i < this.length; i++) {
    if (this[i] == value) return i;
  }
  return -1;
};

Array.prototype.remove = function (value) {
  var index = this.indexOf(value);
  if (index > -1) {
    this.splice(index, 1);
  }
};
Array.prototype.indexMax = function (obj) {
  var i = this.length;
  for (i; i != 0; i -= 1) {
    if (this[i] === obj) {
      return i;
    }
  }
  //return false;
};

getUuid = function () {
  var uid = [];
  var hexDigits = "0123456789abcdefghijklmnopqrst";
  for (var i = 0; i < 32; i++) {
    uid[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  uid[6] = "4";
  uid[15] = hexDigits.substr((uid[15] & 0x3) | 0x8, 1);
  var uuid = uid.join("");
  return uuid;
};
//根据子节点ID，递归遍历获取所有父级路径[1,3,5]含自身ID
getParents = (data, value, parents = []) => {
  data.some((item) => {
    //如果找到节点则停止递归
    if (item.id === value) {
      //添加自身节点ID
      parents.push(value);
      return true;
    } else {
      //如果parentId为0则重置parents
      if (item.parentId === 0) {
        parents = [];
      }
      item.children && getParents(item.children, value, parents);
    }
  });
  return parents;
};

getParent = (data2, nodeId2) => {
  var arrRes = [];
  if (data2.length == 0) {
    if (nodeId2) {
      arrRes.unshift(nodeId2);
    }
    return arrRes;
  }
  let rev = (data, nodeId) => {
    for (var i = 0, length = data.length; i < length; i++) {
      let node = data[i];
      if (node.id == nodeId) {
        arrRes.unshift(nodeId);
        rev(data2, node.parentId);
        break;
      } else {
        if (node.children) {
          rev(node.children, nodeId);
        }
      }
    }
    return arrRes;
  };
  arrRes = rev(data2, nodeId2);
  return arrRes;
};

//返回折线图下边的表格数据
renderTable = (xAxisData, data) => {
  let tableData = [];
  let i = 0;
  for (let key in data) {
    let max = Math.max.apply(null, data[key]);
    let min = Math.min.apply(null, data[key]);
    tableData.push({
      factor: key,
      max: max,
      maxTime: xAxisData[data[key].indexOf(max)],
      min: min,
      minTime: xAxisData[data[key].indexOf(min)],
      average: average(data[key]).toFixed(2),
      Index: i,
    });
    i++;
  }
  return tableData;
};

//求平均值
average = (nums) => {
  console.log(nums);
  if (nums.length > 0) {
    return nums.reduce((a, b) => a + b) / nums.length;
  } else {
    return 0;
  }
};
