<template>
  <a-config-provider :locale="$zhCN">
    <div id="app">
      <router-view />
    </div>
  </a-config-provider>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
  created() {
    this.notification();
  },
  methods: {
    //申请浏览器通知权限，具体参见上面文档链接
    notification() {
      let that = this;
      if (!("Notification" in window)) {
        console.log("浏览器不支持消息通知");
        return;
      }
      Notification.requestPermission(function (permission) {});
    },
  },
};
</script>
<style lang="less" scoped>
#app {
  min-height: 100vh !important;
  display: flex;
}
</style>
