/**
 * Http请求封装类
 * axios参考：https://segmentfault.com/a/1190000008470355#articleHeader16
 */
import qs from "qs";
import axios from "axios";
import router from "@/router/";
import store from "@/store/";
import { message } from "ant-design-vue";
import "ant-design-vue/dist/antd.css";
// axios 配置
axios.defaults.timeout = 30000;
axios.defaults.baseURL = process.env.VUE_APP_URL;
axios.defaults.withCredentials = false; // 设置请求携带cookies
let tokenAbnormal = false;
let isRefreshToken = false;
let pending = []; //声明一个数组用于存储每个ajax请求的取消函数和ajax标识
let cancelToken = axios.CancelToken; //取消请求
let removePending = (ever) => {
  for (let p in pending) {
    if (pending[p].u === ever.url + "&" + ever.method) {
      //当当前请求在数组中存在时执行函数体
      pending[p].f(); //执行取消操作
      pending.splice(p, 1); //把这条记录从数组中移除
    }
  }
};
// 添加请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 每次发起请求前取消掉在进行中的相同请求
    removePending(config);
    config.cancelToken = new cancelToken((c) => {
      // 这里的ajax标识我是用请求地址&请求方式拼接的字符串，当然你可以选择其他的一些方式
      pending.push({ u: config.url + "&" + config.method, f: c });
    });
    // 如果url纯在ak字段，修改banseUrl
    if (config.url.indexOf("ak") > -1) {
      config.baseURL = "";
    }
    // 查看mqtt
    if (config.url.indexOf("ws") > -1) {
    }
    if (config.url == "/file/upload") {
      config.headers["Content-Type"] = "multipart/form-data";
    }
    if (config.url == "/log/sync") {
      config.headers["Content-Type"] = "application/x-www-form-urlencoded ";
    }
    if (store.getters.Authorization) {
      config.headers[
        "Authorization"
      ] = `Bearer ${store.getters.Authorization.token}`;
    }
    return config;
  },
  (error) => {
    message.error("请求超时");
    return Promise.reject(error);
  }
);
// 添加返回拦截器
axios.interceptors.response.use(
  (response) => {
    //在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
    removePending(response.config);
    if (!isRefreshToken) {
      if (store.getters.TokenExpire - 60000 * 10 <= new Date().getTime()) {
        if (store.state.User.Authorization) {
          store.dispatch("refreshToken");
          isRefreshToken = true;
        }
      }
    }
    return response.data;
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          if (!tokenAbnormal) {
            tokenAbnormal = true;
          }
          message.error(error.response.data.msg);
          setTimeout(() => {
            tokenAbnormal = false;
            // 返回 401 清除token信息并跳转到登录页面
            if (window.location.pathname != "/login") {
              localStorage.clear();
              router.replace({
                path: "/login",
              });
            }
          }, 3000);
          break;
        default:
          message.error(error.response.data.msg);
          break;
      }
    }
    // 返回接口返回的错误信息
    return Promise.reject(error);
  }
);

export default axios;
