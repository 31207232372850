import http from "@/api/Http";
const Department = {
  state: {
    departmentList: JSON.parse(localStorage.getItem("departmentList") || "[]"),
  },
  getters: {
    departmentList(state) {
      return state.departmentList;
    },
  },
  mutations: {
    adddpartment(state, data) {
      state.departmentList = data;
      localStorage.setItem("departmentList", JSON.stringify(data));
    },
  },
  actions: {
    Savesepartment(context, data) {
      http
        .get("/department/all")
        .then((res) => {
          if (res.code == 1) {
            context.commit("adddpartment", res.data);
          }
        })
        .catch(() => {});
    },
  },
};
export default Department;
