const Updata = {
  state: {
    updataId: localStorage.getItem("updataId") || "",
    handleStatus: localStorage.getItem("handleStatus") || false,
  },
  getters: {
    updataId(state) {
      return state.updataId;
    },
    handleStatus(state) {
      return state.handleStatus;
    },
  },
  mutations: {
    setStatus(state, data) {
      state.handleStatus = data;
      localStorage.setItem("handleStatus", data);
    },
    setHandleId(state, data) {
      state.updataId = data;
      localStorage.setItem("updataId", data);
    },
  },
  actions: {
    // 获取同步状态，正在进行，已完成
    getStatus(context, data) {
      context.commit("setStatus", data.status);
      context.commit("setHandleId", data.id);
    },
  },
};
export default Updata;
