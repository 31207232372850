import http from "@/api/Http";
const OperMenu = {
  state: {
    operMenuList: JSON.parse(localStorage.getItem("operMenuList") || "[]"),
  },
  getters: {
    operMenuList(state) {
      return state.operMenuList;
    },
  },
  mutations: {
    updateMenuList(state, data) {
      state.operMenuList = data;
      localStorage.setItem("operMenuList", JSON.stringify(data));
    },
  },
  actions: {
    SaveMenuList(context, data) {
      http
        .get("/menu")
        .then((res) => {
          if (res.code == 1) {
            context.commit("updateMenuList", res.data);
          }
        })
        .catch(() => {});
    },
  },
};
export default OperMenu;
