import http from "@/api/Http";
//递归遍历生成菜单
const generateTree = (target, init = [], paths = []) => {
  target.forEach((item, index) => {
    //如果为同级则删除最后一个无素并新拼接
    index > 0 && paths.pop();
    //组成父级节点路径
    paths = paths.concat(item.id);
    init.push({
      key: paths.join(","),
      value: item.id,
      label: item.name,
      sort: item.sort,
      isLeaf: item.isLeaf,
      parentId: item.parentId,
      customerId: item.customerId,
      scopedSlots: { title: "title" },
      children: item.waterNodes && generateTree(item.waterNodes, [], paths),
    });
  });
  return init;
};
const deviceMenu = {
  state: {
    deviceMenu: JSON.parse(localStorage.getItem("deviceMenu") || "[]"),
  },
  getters: {
    getDeviceMenu(state) {
      return state.deviceMenu;
    },
  },
  mutations: {
    updateDeviceMenu(state, data) {
      state.deviceMenu = data;
      localStorage.setItem("deviceMenu", JSON.stringify(data));
    },
  },
  actions: {
    SaveDeviceMenu(context, data) {
      http
        .get("waterUnit/menu")
        .then((res) => {
          if (res.code == 1) {
            var treeData = [];
            var options = res.data;
            //调用递归生成菜单
            generateTree(options, treeData);
            //缓存菜单数据
            context.commit("updateDeviceMenu", treeData);
          }
        })
        .catch(() => {});
    },
  },
};
export default deviceMenu;
