import http from "@/api/Http";
const User = {
  state: {
    User: null,
    Authorization: localStorage.getItem("Authorization")
      ? JSON.parse(localStorage.getItem("Authorization"))
      : false,
  },
  //计算属性
  getters: {
    Authorization(state) {
      if (state.Authorization == false) {
        return false;
      } else {
        if (!state.Authorization) {
          return false;
        }
        if (state.Authorization.expire_in < new Date().getTime()) {
          return false;
        } else {
          return state.Authorization;
        }
      }
    },
    TokenExpire(state) {
      if (state.Authorization) {
        return state.Authorization.expire_in;
      } else {
        return new Date().getTime();
      }
    },
    User(state) {
      if (state.User != null) {
        return state.User;
      } else if (localStorage.getItem("user") != null) {
        return JSON.parse(localStorage.getItem("user"));
      } else {
        return null;
      }
    },
    userId(state) {
      if (state.User != null) {
        return state.User.id;
      } else if (localStorage.getItem("user") != null) {
        return JSON.parse(localStorage.getItem("user")).id;
      } else {
        return 0;
      }
    },
    Company(state) {
      if (state.User != null) {
        return state.User.company;
      } else if (localStorage.getItem("user") != null) {
        let user = JSON.parse(localStorage.getItem("user"));
        return user.company;
      } else {
        return null;
      }
    },
    CompanyId(state) {
      if (state.User != null) {
        return state.User.company.id;
      } else if (localStorage.getItem("user") != null) {
        let user = JSON.parse(localStorage.getItem("user"));
        return user.company.id;
      } else {
        return null;
      }
    },
  },
  //更改store中的状态
  mutations: {
    Authorization(state, data) {
      state.Authorization = data;
      localStorage.setItem("Authorization", JSON.stringify(data));
    },
    LOGIN(state, data) {
      state.User = data;
      localStorage.setItem("user", JSON.stringify(data));
      window.location.reload();
    },
    LOGOUT(state) {
      state.Authorization = null;
      state.User = null;
      localStorage.removeItem("Authorization");
      localStorage.removeItem("user");
      localStorage.clear();
    },
  },
  actions: {
    //刷新Token
    async refreshToken(store, config = {}) {
      const { commit, state } = store;
      const { code, data } = await http.get(
        process.env.VUE_APP_URL + "/oauth/refreshToken",
        config
      );
      if (code == 1) {
        commit("Authorization", data);
      }
    },
    async Login(store, config = {}) {
      const { commit, state } = store;
      commit("Authorization", config);
      const { code, data } = await http.get(
        process.env.VUE_APP_URL + "/oauth/userinfo",
        config
      );
      if (code == 1) {
        commit("LOGIN", data);
      }
    },
    Logout(context) {
      context.commit("LOGOUT");
    },
  },
};
export default User;
