import http from "@/api/Http";
import router from "@/router";
//生成路由表
function filterRouter(routers) {
  // 遍历后台传来的路由字符串，转换为组件对象
  const maps = routers.filter((route) => {
    let { component, children, path } = route;
    if (path == "/") {
      route.redirect = "/dashboard";
    }
    route.component = (resolve) => require([`@/views/${component}`], resolve);
    if (children instanceof Array && children.length > 0) {
      route.children = filterRouter(children);
    }
    return true;
  });
  return maps;
}
const Menu = {
  // namespaced: true,
  state: {
    routes: {},
  },
  getters: {
    routes(state) {
      return state.routes;
    },
  },
  mutations: {
    addRouters(state, data) {
      state.routes = data;
    },
  },
  actions: {
    async initRoutes(store, config = {}) {
      const { commit } = store;
      const { code, data } = await http.get(
        process.env.VUE_APP_URL + "/config/menus",
        config
      );
      // console.log(data);
      if (code == 1) {
        let routes = filterRouter(data);
        /*  初始路由 */
        router.$addRoutes(routes);
        commit("addRouters", data);
      }
    },
  },
};
export default Menu;
