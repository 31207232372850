import http from "@/api/Http";
const Roles = {
  // namespaced: true,
  state: {
    roles: JSON.parse(localStorage.getItem("rolesList") || "[]"),
  },
  getters: {
    rolesList(state) {
      if (state.roles != null) {
        return state.roles;
      } else {
        return JSON.parse(localStorage.getItem("roles"));
      }
    },
  },
  mutations: {
    saveRoles(state, data) {
      state.roles = data;
      localStorage.setItem("roles", JSON.stringify(data));
    },
  },
  actions: {
    //   async roles(store, config = {}) {
    //     const { commit } = store;
    //     const { code, data } = await http.get(
    //       process.env.VUE_APP_URL + "/roles",
    //       config
    //     );
    //     if (code == 1) {
    //       commit("saveRoles", data);
    //     }
    //   },
    addRoles(context, data) {
      http
        .get("/role")
        .then((res) => {
          if (res.code == 1) {
            context.commit("saveRoles", res.data);
          }
        })
        .catch(() => {});
    },
  },
};
export default Roles;
