import http from "@/api/Http";
const Device = {
  state: {
    typeList: JSON.parse(localStorage.getItem("typeList") || "[]"),
    unitList: JSON.parse(localStorage.getItem("unitList") || "[]"),
    nodeList: JSON.parse(localStorage.getItem("nodeList") || "[]"),
    checkList: JSON.parse(localStorage.getItem("checkList") || "[]"),
  },
  getters: {
    typeList(state) {
      return state.typeList;
    },
    unitList(state) {
      return state.unitList;
    },
    nodeList(state) {
      return state.nodeList;
    },
    checkList(state) {
      return state.checkList;
    },
  },
  mutations: {
    addType(state, data) {
      state.typeList = data;
      localStorage.setItem("typeList", JSON.stringify(data));
    },
    addUnit(state, data) {
      state.unitList = data;
      localStorage.setItem("unitList", JSON.stringify(data));
    },
    addNode(state, data) {
      state.nodeList = data;
      localStorage.setItem("nodeList", JSON.stringify(data));
    },
    addCheck(state, data) {
      state.checkList = data;
      localStorage.setItem("checkList", JSON.stringify(data));
    },
  },
  actions: {
    // 获取用水单元
    SaveUnit(context, data) {
      http
        .get("/waterUnit")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addUnit", res.data);
          }
        })
        .catch(() => {});
    },
    // 获取测量点
    SaveNode(context, data) {
      http
        .get("/waterNode")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addNode", res.data);
          }
        })
        .catch(() => {});
    },
    // 获取设备类型
    SaveType(context, data) {
      http
        .get("/deviceType")
        .then((res) => {
          if (res.code == 1) {
            context.commit("addType", res.data);
          }
        })
        .catch(() => {});
    },
    // 获取检测类型
    SaveCheck(context, data) {
      context.commit("addCheck", data);
    },
  },
};
export default Device;
